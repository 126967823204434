import React, {FC, useState} from "react";
import CompanyLogo from "../../../src/assets/images/SR_Meteor_Without_Slogan_Transparent_bg.svg"
import {useAppSelector} from "../../redux/hooks";
import {Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, Tooltip, Typography} from "@mui/material";
import {
  BulletTypography,
  ExpandMoreCustom,
  fullCardBackground,
  fullCardBorder,
  fullCardShadow
} from "../custom-utility";
import {BulletMinusIcon, ExpandMoreIcon} from "../../assets/icons";
import EMailContact from "./e-mail-contact";


const TalkingIp: FC = () => {
  const darkMode = useAppSelector(s => s.colorMode.darkMode);

  return (
    <a href="https://www.talkingip.com" target="_blank" rel="noopener noreferrer" style={{
      color: darkMode ? "#ADD8E6" : "#0000EE"
    }}>
      Talking-IP LLC
    </a>
  )
}

interface AboutProps {
}

export const About: FC<AboutProps> = () => {
  const [more, setMoreExpanded] = useState(false);

  const darkMode = useAppSelector(s => s.colorMode.darkMode);
  const chromeMode = useAppSelector(s => s.colorMode.color);

  const handleMoreExpandClick = () => {
    setMoreExpanded(!more);
  };

  return (
    <Card
      elevation={3}
      sx={{
        m: 2,
        mt: 5,
        width: {xs: 320, sm: 400, md: 500, lg: 600, xl: 750},
        minWidth: 260,
        backgroundImage: fullCardBackground(darkMode, chromeMode),
        boxShadow: fullCardShadow(darkMode, chromeMode),
        border: fullCardBorder(darkMode, chromeMode),
      }}
    >
      <CardHeader
        title="SR Meteor"
        subheader="About Company"/>
      <Tooltip title={`©SR Meteor 2024: All rights reserved`} arrow placement="top-end">
        <CardMedia
          component="img"
          image={CompanyLogo}
          // width="20%"
          sx={{

            backgroundSize: 'cover',
            width: "96%",
            ml: "2%",
            filter: darkMode
              ? "invert(1) sepia(1) saturate(1) hue-rotate(180deg)"
              : "none",
          }}
          alt={`SR Meteor Logo`}
        />
      </Tooltip>
      <CardContent>
        <Typography paragraph>
          Providing Software Support since 2005<br/>
          Providing Crestron Software Support since 2015<br/>
          <EMailContact/>
        </Typography>
        <CardActions disableSpacing>
          <Typography onClick={handleMoreExpandClick} variant="button" ml={1}>
            More ...
          </Typography>
          <ExpandMoreCustom
            expand={more}
            onClick={handleMoreExpandClick}
            aria-expanded={more}
            aria-label="show more"
          >
            <ExpandMoreIcon/>
          </ExpandMoreCustom>
        </CardActions>
        <Collapse
          timeout="auto"
          unmountOnExit
          in={more}>
          <BulletTypography text="
            2005: Company Registered for ...
          " children={
            <>
              <BulletTypography text="
                Developing and building Microcontroller units for Industrial purposes
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Programming Industrial PLCs
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
            </>
          }/>
          <BulletTypography text="
            2014: Started to work with Crestron Commercial Multimedia Systems
          " children={
            <>
              <BulletTypography text="
                Multy-Purpose Hotel Conference-Presentation-Congress-Divided rooms
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Small Meeting-Presentation rooms
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Video-Conference rooms
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
            </>
          }/>
          <BulletTypography text="
            2019: Started to use SimplSharp® and SimplSharpPro® (VS2008)
          " children={
            <>
              <BulletTypography text="
                Upgrading SimplPlus modules to use SimplSharpLibrary for
                communication, file management, ...
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Small-Systems built completely with SimplSharpPro
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
            </>
          }/>
          <BulletTypography text="
            2020: Created first version of
            Residence Framework with EventScheduler in cooperation
            with
          " children={
            <>
              <TalkingIp/>
              <BulletTypography text="
                Daily Scheduler for Lights, Shades and Thermostats
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                First version of Hebrew Calendar with Shabbat-Holiday Scheduler
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Supporting 3-Series (WinCE) and newly coming 4-Series (Linux) Crestron processors
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                SmartGraphics® XPanel Desktop and iPad for complete configuration and
                system setup
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
            </>
          }/>
          <BulletTypography text="
            2022: Started using c# for Crestron 4-Series Processors
          " children={
            <>
              <BulletTypography text="
                Upgraded existing framework to c#
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Upgraded Daily and Holiday Scheduler
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Upgraded SmartGraphics Configuration and Setup Application
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Created Intercom system with Virtual SIP server, supporting
                Akuvox and/or Mobotix Door Stations with Crestron Touchpanels as
                Intercom devices
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Created Sprinklers (garden system) with 8 zones, automatically
                determine should it start using weather data and/or local rain sensor
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Added support to use Lutron Caseta Light Loads and Keypads
                together with Crestron Devices
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
            </>
          }/>
          <BulletTypography text="
            2023: Started using Crestron VC-4 Servers and React-Typescript for User Interface
          " children={
            <>
              <BulletTypography text="
                Tested and upgraded existing Framework to be compatible with
                Crestron VC-4 Servers
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Upgraded Scheduler to work with server
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Built First React-Typescript User Interface and tested it
                on various hardware (Crestron and 3rd party Touchpanels, Web XPanel)
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Implemented Lights, Shades, Thermostats and Audio control
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Created Weather API with Weather Widget, weather data
                usable for control system
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Started implementing system configuration and system setup
                on HTML user interface
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Stopped updating old SimplSharpPro (Crestron 3-Series) systems
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                Created Bridge Application for Crestron 3-Series Processor so the processor
                hardware can be used with VC-4 server: Cresnet®, Digital Inputs-Outputs, Serial Ports, ...
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
            </>
          }/>
          <BulletTypography text="
            2024: Converted Framework to Library and Created ResidenceApp
          " children={
            <>
              <BulletTypography text="
                ResidenceApp, universal for Crestron 4-series processors and VC-4 servers
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                residence (ReactTS project), universal for all supported panels
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
              <BulletTypography text="
                still working on improvements ...
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}}/>}/>
            </>
          }/>
        </Collapse>
      </CardContent>
    </Card>
  );
};
