import {Box, Card, Typography} from "@mui/material";
import React, {ReactElement} from "react";

export function Disclaimer(): ReactElement {

  return (
    <Card
      sx={{
        display: "block",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 1199,
        width: "100%",
        height: 40,
      }}
      elevation={2}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        overflow="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Typography fontSize="0.8rem" alignSelf="center">
          ©SR Meteor 2024
        </Typography>
        <Typography fontSize="0.7rem" alignSelf="center">
          Images generated using AI trough ®Image Creator
        </Typography>
      </Box>
    </Card>
  );
}

export default Disclaimer;
