import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store";
import {Action, isAction, Middleware} from "@reduxjs/toolkit";

export const useAppDispatch: any = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Middleware to enable dispatch actions from reducers (Somebody on Net wrote this and it's working fine)
export const asyncDispatchMiddleware: Middleware =
  (store) => (next) => (action) => {
    let syncActivityFinished = false;
    let actionQueue: Action[] = [];

    function flushQueue() {
      actionQueue.forEach((a) => store.dispatch(a)); // flush queue
      actionQueue = [];
    }

    function asyncDispatch(asyncAction: Action) {
      actionQueue = actionQueue.concat([asyncAction]);

      if (syncActivityFinished) {
        flushQueue();
      }
    }

    const actionWithAsyncDispatch = isAction(action)
      ? { ...action, asyncDispatch }
      : undefined;

    next(actionWithAsyncDispatch as any);
    syncActivityFinished = true;
    flushQueue();
  };
