import React, {FC, useEffect} from "react";
import {Box} from "@mui/material";
import {Helmet} from "react-helmet-async";
import ContactForm from "../../components/contact-form";


interface ContactPageProps {
}

export const ContactPage: FC<ContactPageProps> = (props) => {
  useEffect(() => {
    document.title = "Contact";
  }, []);
  return (
    <Box
      // mb={10}
      p={5}
      width="100vw"
      height="95vh"
      minHeight="95vh"
      position="fixed"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-evenly"
      justifyItems="center"
      alignItems="flex-start"
      overflow="auto"
      sx={{
        backgroundColor: "transparent",
        WebkitOverflowScrolling: "touch"
      }}
    >
      <Helmet>
        <title>SR Meteor - Contact</title>
        <meta name="description" content="Contact Form" />
      </Helmet>
      <ContactForm />
    </Box>
  );
}

export default ContactPage;
