import React, {FC} from "react";
import {Typography} from "@mui/material";

interface TitleBlockProps {
  title: string;
}

const TitleBlock: FC<TitleBlockProps> = (props) => {
  const {title} = props;
  return (
    <Typography>{title}</Typography>
  );
};

export default TitleBlock;
