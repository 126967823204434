import React, {FC} from "react";
import {AppBar, Box, Toolbar} from "@mui/material";
import InstallPwaComponent from "../pwa-install";
import ColorModeSet from "./color-mode-set";
import MainMenu from "./main-menu";
import TitleBlock from "./title-block";

interface HeaderProps {
  title?: string;
}

const Header: FC<HeaderProps> = (props) => {
  const {title = "SR Meteor - Software Develop"} = props;
  return (
      <Box display="flex" alignItems="center" sx={{
        width: "100%",
        // border: "dotted 3px yellow"
      }}
      >
        <AppBar>
          <Toolbar sx={{

            position: "sticky",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // border: "dotted 3px red",

          }}>
            <InstallPwaComponent />
            <MainMenu />
            <TitleBlock title={title} />
            <ColorModeSet />
          </Toolbar>
        </AppBar>
      </Box>
  );
};

export default Header;
