import React, {useEffect, useState} from 'react';
import emailjs from 'emailjs-com';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FilledInput,
  FormControl, FormHelperText,
  InputLabel,
} from "@mui/material";
import {
  fullCardBackground,
  fullCardBorder,
  fullCardShadow,
  validateEmail
} from "../custom-utility";
import {useAppSelector} from "../../redux/hooks";
import ReCAPTCHA from "react-google-recaptcha";
import useRecaptcha from '../../hooks/use-recaptcha';
import {useNavigate} from "react-router-dom";


export const ContactForm: React.FC = () => {
  const {capchaToken, recaptchaRef, handleRecaptcha} = useRecaptcha();
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [company, setCompany] = useState<string>("");
  const [companyError, setCompanyError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageError, setMessageError] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);
  const [checkError, setCheckError] = useState<boolean>(false)

  const darkMode = useAppSelector(s => s.colorMode.darkMode);
  const chromeMode = useAppSelector(s => s.colorMode.color);

  const navigate = useNavigate();

  const onCaptcha = (token: string | null) => {
    setCheckError(true);
    handleRecaptcha(token);
  }


  const clearForm = () => {
    setName("");
    setCompany("");
    setEmail("");
    setMessage("");
    setNameError(false);
    setCompanyError(false);
    setEmailError(false);
    setMessageError(false);
    recaptchaRef.current?.reset();
    setCheckError(false);
  }

  useEffect(() => {
    if (checkError) {
      setNameError(name.length === 0);
      setCompanyError(company.length === 0);
      setEmailError(!validateEmail(email));
      setMessageError(message.length === 0);

    }
    // console.log(`update error status => check: ${checkError}`);
  }, [checkError, name, company, email, message]);

  useEffect(() => {
    setFormError(nameError || companyError || emailError || messageError);
    // console.log(`UpdateFormError Status: ${nameError || companyError || emailError || messageError}`)
  }, [nameError, companyError, emailError, messageError]);

  const onSubmit = () => {

    if (!formError) {
      // console.log(`### srvId: ${process.env
      //   .REACT_APP_EMAILJS_SERVICE_ID}, tplId: ${process.env
      //   .REACT_APP_EMAILJS_TEMPLATE_ID}, usrId: ${process.env
      //   .REACT_APP_EMAILJS_USER_ID}, recaptchaKey: ${process.env
      //   .REACT_APP_RECAPTCHA_SITE_KEY}, recaptchaSecret: ${process.env
      //   .REACT_APP_SITE_SECRET}`);
      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
        {
          name: name.trim(),
          company: company.trim(),
          email: email.trim(),
          message: message.trim()
        },
        process.env.REACT_APP_EMAILJS_USER_ID as string)
        .then((result) => {
          console.log(`### Sending Contact: ${result.text}`);
          alert("Thank you for contacting us, somebody will contact you soon.");
          navigate("/", {replace: true});
        }, (error) => {
          alert(`### Error Sending Contact: ${error.text}`);
        });
    }

    // Clear Form
    clearForm();
  };


  return (
    <Card
      elevation={5}
      sx={{
        m: 2,
        mt: 5,
        width: {xs: 320, sm: 380, md: 435, lg: 520, xl: 600},
        minWidth: 260,
        backgroundImage: fullCardBackground(darkMode, chromeMode),
        boxShadow: fullCardShadow(darkMode, chromeMode),
        border: fullCardBorder(darkMode, chromeMode),
      }}
    >
      <CardHeader
        title="Contact us"
        // subheader="Please, fill the Form and specify if you have specific request"
      />
      <CardContent>
        <Box width="100%">
          <FormControl variant="outlined" fullWidth>
            <FormControl margin="dense" variant="filled" sx={{width: "70%"}}>
              <InputLabel htmlFor="Name">
                Name
              </InputLabel>
              <FilledInput
                error={nameError}
                id="name-edit"
                placeholder="Name"
                value={name}
                aria-describedby="name-edit-text"
                onChange={(event) =>
                  setName(
                    event.target.value as string,
                  )
                }
              />
              {nameError && (
                <FormHelperText error id="name-edit-error-text">
                  Can't be Empty!
                </FormHelperText>
              )}
            </FormControl>
            <FormControl margin="dense" variant="filled" sx={{width: "70%"}}>
              <InputLabel htmlFor="Company">
                Company
              </InputLabel>
              <FilledInput
                error={companyError}
                id="company-edit"
                placeholder="Company"
                value={company}
                aria-describedby="company-edit-text"
                onChange={(event) =>
                  setCompany(
                    event.target.value as string,
                  )
                }
              />
              {companyError && (
                <FormHelperText error id="company-edit-error-text">
                  Can't be Empty!
                </FormHelperText>
              )}
            </FormControl>
            <FormControl margin="dense" variant="filled" sx={{width: "70%"}}>
              <InputLabel htmlFor="e-mail">
                EMail
              </InputLabel>
              <FilledInput
                error={emailError}
                id="email-edit"
                placeholder="EMail"
                value={email}
                aria-describedby="email-edit-text"
                onChange={(event) =>
                  setEmail(
                    event.target.value as string,
                  )
                }
              />
              {emailError && (
                <FormHelperText error id="email-edit-error-text">
                  Must be valid EMail address!
                </FormHelperText>
              )}
            </FormControl>
            <FormControl margin="dense" variant="filled">
              <InputLabel htmlFor="message" sx={{mb: 5}}>
                Message
              </InputLabel>
              <FilledInput
                error={messageError}
                id="message-edit"
                placeholder="Message"
                value={message}
                aria-describedby="message-edit-text"
                multiline
                fullWidth
                maxRows={20}
                minRows={3}
                onChange={(e) =>
                  setMessage(e.target.value as string)
                }
              />

              {messageError && (
                <FormHelperText error id="message-edit-error-text">
                  Can't be Empty!
                </FormHelperText>
              )}
            </FormControl>
            <br/>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
              onChange={onCaptcha}
              // theme={darkMode ? "dark" : "light"}
              theme={darkMode ? "dark" : "light"}
            />
          </FormControl>
        </Box>
      </CardContent>
      <CardActions>
        <Box width="100%" display="flex" alignItems="space-between">
          <Button onClick={clearForm}>Cancel</Button>
          <Button disabled={!capchaToken || (formError && checkError)} onClick={onSubmit}>Send</Button>
        </Box>
      </CardActions>
    </Card>

  );
};

export default ContactForm;
