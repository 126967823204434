import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { HashRouter as Router } from "react-router-dom";
import {persistor, store} from "./redux/store";

import App from "./App";
import {PersistGate} from "redux-persist/integration/react";
import {HelmetProvider} from "react-helmet-async";


const container = document.getElementById("root")!;
const root = createRoot(container);



root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App />
        </Router>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
