import React from 'react';
import {useAppSelector} from "../../../redux/hooks";

const EmailContact: React.FC = () => {
  const darkMode = useAppSelector(s => s.colorMode.darkMode);

  return (
    <a href="mailto:sr.meteor@sr-meteor.com?subject=Message%20from%20Website&body=Name:%0d%0aCompany:%0d%0a(please,%20write%20something%20about%20you)" style={{
      color: darkMode ? "#ADD8E6" : "#0000EE"
    }}>
      sr.meteor@sr-meteor.com
    </a>
  )
}

export default EmailContact;
