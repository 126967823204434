import {styled, IconButton, IconButtonProps, Typography, Box} from "@mui/material";
import * as React from "react";
import {FC, ReactElement} from "react";
import {BulletIcon} from "../../assets/icons";
import {EChromeModeOption} from "../../redux/state/colorMode";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const cardBackground = (darkMode: boolean) => {
  return darkMode
    ? "linear-gradient(120deg, #283443 0%,#4ab3b1 100%)"
    : "linear-gradient(120deg, #94afb9 0%,#eaf0f0 100%)";
};

export const fullCardBackground = (darkMode: boolean, chromeMode: EChromeModeOption) => {
  return chromeMode === EChromeModeOption.Color
    ? cardBackground(darkMode)
    : "transparent";
};

export const cardBackgroundImportant = (darkMode: boolean) => {
  return darkMode
    ? "linear-gradient(120deg, #5f3f4e 0%,#dd3939 100%)"
    : "linear-gradient(120deg, #c37873 0%,#f6dcdc 100%)";
};

export const cardBackgroundSelected = (darkMode: boolean) => {
  return darkMode
    ? "linear-gradient(120deg, #222934 0%,#336464 100%)"
    : "linear-gradient(120deg, #5092aa 0%,#98c8c8 100%)";
};

export const cardShadow = (darkMode: boolean) => {
  return darkMode ? "5px 8px 0 0 #535353c5" : "5px 8px 0 0 #39343466";
};

export const fullCardShadow = (darkMode: boolean, chromeMode: EChromeModeOption) => {
  return chromeMode === EChromeModeOption.Color
    ? cardShadow(darkMode)
    : "none";
};

export const fullCardBorder = (darkMode: boolean, chromeMode: EChromeModeOption) => {
  return chromeMode === EChromeModeOption.Color
    ? "none"
    : darkMode ? "none" : "dotted gray 0.5px";
};

export const CoverImage = styled("div")({
  width: 100,
  height: 100,
  objectFit: "cover",
  overflow: "hidden",
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.08)",
  "& > img": {
    width: "100%",
  },
});

export const TinyText = styled(Typography)({
  fontSize: "0.80rem",
  opacity: 0.9,
  fontWeight: 500,
  letterSpacing: 0.2,
});

export const ExpandMoreCustom = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.standard,
  }),
}));

interface BulletTypographyProps {
  text: string;
  bulletIcon?: ReactElement;
  children?: ReactElement;
  align?: "left" | "right" | "center" | "inherit" | "justify";
}

export const BulletTypography: FC<BulletTypographyProps> = (props) => {
  const {
    text,
    bulletIcon = <BulletIcon sx={{ fontSize: "0.8rem"}}/>,
    children,
    align = "left"
  } = props
  return(
    <Box component="span" display="flex" flexDirection="row" mt={1}>
      <IconButton sx={{alignSelf : "start", mt: -0.2}}>{bulletIcon}</IconButton>
      <Typography component="span" align={align}>
        {text}
        {children}
      </Typography>
    </Box>
  );
};

export const validateEmail = (email: string): boolean => {
  // Regex for email validation
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
