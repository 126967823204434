import React, {FC, useEffect, useState} from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Tooltip,
  Typography
} from "@mui/material";
import {
  BulletTypography,
  ExpandMoreCustom,
  fullCardBackground,
  fullCardBorder,
  fullCardShadow
} from "../custom-utility";
// import {useAppSelector} from "../../redux/hooks";
import Image1 from "../../../src/assets/images/Touchscreens-1.jpg";
import Image2 from "../../../src/assets/images/Touchscreens-2.jpg";
import Image3 from "../../../src/assets/images/Touchscreens-3.jpg";
import Image4 from "../../../src/assets/images/Touchscreens-4.jpg";
import {BulletMinusIcon, ExpandMoreIcon} from "../../assets/icons";
import {useAppSelector} from "../../redux/hooks";

interface TouchpanelInfoProps {
}

const images = [Image1, Image2, Image3, Image4];

export const TouchpanelInfo: FC<TouchpanelInfoProps> = () => {
  const [imgIndex, setImgIndex] = useState<number>(3);
  const [isSwitching, setIsSwitching] = useState(false);

  const [more, setMoreExpanded] = useState(false);

  const darkMode = useAppSelector(s => s.colorMode.darkMode);
  const chromeMode = useAppSelector(s => s.colorMode.color);

  useEffect(() => {
    const slider = setInterval(() => {
      setIsSwitching(true); // Set switching state to true
      //wait for as long as your transition duration then update the state
      setTimeout(() => {
        setImgIndex(prevInd => (prevInd + 1) % images.length);
        setIsSwitching(false); // Set switching state to false
      }, 1000); // corresponds to transition duration on your css
    }, 15000); // slider timer

    return () => {
      clearInterval(slider);
    }
  }, []);

  const handleMoreExpandClick = () => {
    setMoreExpanded(!more);
  };

  return (
    <Card
      elevation={5}
      sx={{
        m: 2,
        mt: 5,
        width: { xs: 320, sm: 380, md: 435, lg: 520, xl: 600 },
        minWidth: 260,
        backgroundImage: fullCardBackground(darkMode, chromeMode),
        boxShadow: fullCardShadow(darkMode, chromeMode),
        border: fullCardBorder(darkMode, chromeMode),
      }}
    >
      <CardHeader
        title="Touchpanels"
        subheader="HTML5 User Interface (Crestron® and 3rd party)" />
      <Tooltip title={`generated using ®Image Creator`} arrow placement="top-end">
        <CardMedia
          component="img"
          image={images[imgIndex]}
          // width="20%"
          sx={{
            transition: "opacity 1s ease-in-out",
            opacity: isSwitching ? 0 : 1, // The image will fade in smoothly.
            // backgroundImage: `url(${images[imgIndex]})`,
            backgroundSize: 'cover',
            width: "96%",
            ml: "2%",
            // mr: 1,
          }}
          alt={`Touchscreen-${imgIndex + 1}`}
        />
      </Tooltip>
      <CardContent>
        <Typography paragraph >
          Start using HTML5 user interface by choosing one of widely
          accepted frameworks before Crestron fully suspend support
          for Flash driven SmartGraphics® and force you to use Construct®.
          If you choose React-Typescript We can help you to ...
        </Typography>
        <CardActions disableSpacing>
          <Typography onClick={handleMoreExpandClick} variant="button" ml={1}>
            More ...
          </Typography>
          <ExpandMoreCustom
            expand={more}
            onClick={handleMoreExpandClick}
            aria-expanded={more}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMoreCustom>
        </CardActions>
        <Collapse
          timeout="auto"
          unmountOnExit
          in={more}>
          <BulletTypography text="
            Create Responsive App so one project can be used For-ALL supported panels:
          " children={
            <>
              <BulletTypography text="
                Web Panel: used with standard browsers: Chrome, Safari, Edge, …
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Crestron Touchscreen: all Crestron Panels, supporting Html5: TSW-60, TS(W)-70 and
                newer generation of Crestron Panels
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                CrestronOne® app: iOS: iPhone, iPad
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Android Devices: phones, tablets, 3rd party touchscreens with Android OS
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
            </>
          }/>
          <BulletTypography text="
            Create State-Storage using React-Redux. States are updated by Crestron ControlSystem using
            CIP (Crestron Over Ip) or custom websocket connection:
          " children={
            <>
              <BulletTypography text="
                CIP connection: can be used only for Crestron Touchpanels or CrestronOne app
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Custom WebSocket connection: can be used for all supported panels
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
            </>
          }/>
          <BulletTypography text="
            Create Dark and Light color modes and set it with user preferences:
          " children={
            <>
              <BulletTypography text="
                Auto: Mode is set using processor Daytime state
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Dark Mode: forced Dark Mode
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Light Mode: forced Light Mode
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
            </>
          }/>
        </Collapse>
      </CardContent>
    </Card>
  );
};
