import React, {FC, useEffect, useState} from "react";
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Menu,
  Typography,
  debounce
} from "@mui/material";
import {MenuIcon} from "../../../assets/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";

const MainMenu: FC = () => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = Boolean(menuAnchor);
  const [curMenu, setCurMenu] = useState<number>(0);

  useAppDispatch();
  const navigate = useNavigate();
  useAppSelector(s => s.colorMode.darkMode);
  const onMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(e.currentTarget);
  };

  const location = useLocation();

  useEffect(() => {
    // console.log(`### Location: ${location.pathname}`);
    setCurMenu(location.pathname.includes("about")
      ? 1
      : location.pathname.includes("contact")
        ? 2
        : 0);
  }, [location]);

  const handleMenuClose = () => {
    setMenuAnchor(null);
  }

  const closeMenuDelayed = debounce(() => {
    handleMenuClose();
  }, 300);

  const onHome = () => {
    navigate("/", {replace: true});
    closeMenuDelayed();
  }

  const onAbout = () => {
    navigate("/about", {replace: true});
    closeMenuDelayed();
  }

  const onContact = () => {
    navigate("/contact", {replace: true});
    closeMenuDelayed();
  }


  return (
    <Box
      sx={{
        height: 50,

        alignContent: "center",
        position: "fixed",
        left: 8,
        top: 8,
        // border: "dotted 3px yellow",
      }}
    >
      <Box sx={{
        display: {xs: "none", md: "flex"},
        flexDirection: "row"
      }}>
        <IconButton onClick={onHome} sx={{ mx : 1 }}>
        <Typography
          fontSize="1rem"
          color="#f5f5f5"
          sx={{
            textDecorationLine: curMenu === 0 ? "underline" : "none",
            textDecorationColor: "inherit",
            textDecorationStyle: "single"
          }}
        >Home</Typography>
        </IconButton>
        <IconButton onClick={onAbout} sx={{ mx : 1 }}>
        <Typography
          fontSize="1rem"
          color="#f5f5f5"
          sx={{
            textDecorationLine: curMenu === 1 ? "underline" : "none",
            textDecorationColor: "inherit",
            textDecorationStyle: "single"
          }}
        >About</Typography>
        </IconButton>
        <IconButton onClick={onContact} sx={{ mx : 1 }}>
          <Typography
            fontSize="1rem"
            color="#f5f5f5"
            sx={{
              textDecorationLine: curMenu === 2 ? "underline" : "none",
              textDecorationColor: "inherit",
              textDecorationStyle: "single"
            }}
          >Contact</Typography>
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <IconButton onClick={onMenuOpen}
                    sx={{display: {xs: "block", md: "none"}, color: "inherit"}}
        >
          <MenuIcon/>
        </IconButton>
        <Typography mt={1} sx={{display: {xs: "none", sm: "block", md: "none"}}}>Menu</Typography>
      </Box>
      {showMenu && (
        <Menu
          id="main-menu"
          aria-labelledby="main-menu"
          anchorEl={menuAnchor}
          open={showMenu}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}

        >
          <MenuItem onClick={onHome} selected={curMenu === 0}>
            <Typography
              sx={{
                textDecorationLine: curMenu === 0 ? "underline" : "none",
                textDecorationColor: "inherit",
                textDecorationStyle: "single"
              }}
            >
              Home
            </Typography>
          </MenuItem>
          <MenuItem onClick={onAbout} selected={curMenu === 1}>
            <Typography
              sx={{
                textDecorationLine: curMenu === 1 ? "underline" : "none",
                textDecorationColor: "inherit",
                textDecorationStyle: "single"
              }}
            >
              About
            </Typography>
          </MenuItem>
          <MenuItem onClick={onContact} selected={curMenu === 2}>
            <Typography
              sx={{
                textDecorationLine: curMenu === 2 ? "underline" : "none",
                textDecorationColor: "inherit",
                textDecorationStyle: "single"
              }}
            >
              Contact
            </Typography>
          </MenuItem>
          <Divider/>
          <MenuItem onClick={closeMenuDelayed}>Close</MenuItem>
        </Menu>
      )}
    </Box>
  );
}

export default MainMenu;
