import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
  EChromeModeOption,
  EColorModeOption,
  setChromeOption,
  setColorMode,
  setColorModeOption
} from "../../../redux/state/colorMode";
import {
  debounce,
  Divider,
  FormControl, FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  MenuItem, Radio, RadioGroup,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import {DarkModeIcon, LightModeIcon} from "../../../assets/icons";
import React, {useEffect, useState} from "react";

const ColorModeSet: React.FC = () => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const showMenu = Boolean(menuAnchor);
  const darkMode = useAppSelector(s => s.colorMode.darkMode);
  const [toolTitle, setToolTitle] = useState<string>("");


  const dispatch = useAppDispatch();

  const sysDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const colorModeOption = useAppSelector(s => s.colorMode.option);
  const chromeModeOption = useAppSelector(s => s.colorMode.color);


  const onMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  }

  const closeMenuDelayed = debounce(() => {
    handleMenuClose();
  }, 200);

  useEffect(() => {
    switch (colorModeOption) {
      case EColorModeOption.Auto:
        if (sysDarkMode !== darkMode) {
          dispatch(setColorMode(sysDarkMode));
        }
        setToolTitle(sysDarkMode ? "Dark Mode (Auto)" : "Light Mode (Auto)");
        break;
      case EColorModeOption.Dark:
        if (!darkMode) {
          dispatch(setColorMode(true));
        }
        setToolTitle("Dark Mode");
        break;
      case EColorModeOption.Light:
        if (darkMode) {
          dispatch(setColorMode(false));
        }
        setToolTitle("Light Mode");
        break;
    }
    if (colorModeOption === EColorModeOption.Auto && sysDarkMode !== darkMode) {
      dispatch(setColorMode(sysDarkMode));
    }
  }, [dispatch, sysDarkMode, darkMode, colorModeOption]);

  const onCmOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = (event.target as HTMLInputElement).value;
    const newCmOption = EColorModeOption[newOption as unknown as EColorModeOption] as unknown as EColorModeOption;
    console.log(`### ColorMode: ${newOption} => ${newCmOption}`)
    dispatch(setColorModeOption(newCmOption));

    closeMenuDelayed();
  };

  const onChromeOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = (event.target as HTMLInputElement).value;
    const newChOption = EChromeModeOption[newOption as unknown as EChromeModeOption] as unknown as EChromeModeOption;
    console.log(`### ChromeMode: ${newOption} => ${newChOption}`)
    dispatch(setChromeOption(newChOption));

    closeMenuDelayed();
  };


  return (
    <>
    <Tooltip title={toolTitle}>
      <IconButton color="inherit" onClick={onMenuOpen}
                  sx={{position: "fixed", right: 8, top: 8}}>
        {darkMode ? <DarkModeIcon/> : <LightModeIcon/>}
      </IconButton>
    </Tooltip>
  {showMenu && (
    <Menu
      id="color-menu"
      aria-labelledby="color-menu"
      anchorEl={menuAnchor}
      open={showMenu}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}

    >
      <FormControl sx={{ml: 2}}>
        <FormLabel id="color-mode-option">Color Mode
        <RadioGroup
          row
          name="color-mode-group"
          aria-labelledby="color-mode-radio-group"
          value={EColorModeOption[colorModeOption]}
          onChange={onCmOptionChanged}
        >
          <FormControlLabel
            value="Auto"
            control={<Radio/>}
            label="Auto"
          />
          <FormControlLabel
            value="Dark"
            control={<Radio/>}
            label="Dark"
          />
          <FormControlLabel
            value="Light"
            control={<Radio/>}
            label="Light"
          />
        </RadioGroup>
        </FormLabel>
      </FormControl>
      <br/>
      <FormControl sx={{ml: 2}}>
        <FormLabel id="chrome-mode-option">Chrome Mode
          <RadioGroup
            row
            name="chrome-mode-group"
            aria-labelledby="chrome-mode-radio-group"
            value={EChromeModeOption[chromeModeOption]}
            onChange={onChromeOptionChanged}
          >
            <FormControlLabel
              value="Monochrome"
              control={<Radio/>}
              label="Monochrome"
            />
            <FormControlLabel
              value="Color"
              control={<Radio/>}
              label="Color"
            />
          </RadioGroup>
        </FormLabel>
      </FormControl>
      <Divider/>
      <MenuItem onClick={closeMenuDelayed}>Close</MenuItem>
    </Menu>
  )}
    </>
  );
};

export default ColorModeSet;
