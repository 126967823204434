import React, {FC, useEffect} from "react";
import {Box} from "@mui/material";
import {TouchpanelInfo} from "../../components/touchpanels";
import {ControlSystem} from "../../components/control-system";

interface HomePageProps {
}

export const HomePage: FC<HomePageProps> = (props) => {
  useEffect(() => {
    document.title = "Home";
  }, []);
  return (
    <Box
      m="auto"
      p={5}
      width="100vw"
      height="95vh"
      minHeight="95vh"
      position="fixed"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-evenly"
      justifyItems="center"
      alignItems="flex-start"
      overflow="auto"
      sx={{
        backgroundColor: "transparent",
        WebkitOverflowScrolling: "touch"
      }}
    >
      <ControlSystem />
      <TouchpanelInfo />
    </Box>
  )
}

export default HomePage;
