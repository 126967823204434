import { combineReducers } from "@reduxjs/toolkit";
import colorMode from "../../state/colorMode";


const stateReducer = combineReducers({

    colorMode,
});

export default stateReducer;
