import {
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";

export enum EColorModeOption {
    Auto = 0,
    Dark = 1,
    Light = 2,
}

export enum EChromeModeOption {
  Monochrome,
  Color,
}



const initialState: IColorMode = {
    darkMode: true,
    option: EColorModeOption.Auto,
    color: EChromeModeOption.Monochrome,
};

export const colorModeSlice = createSlice({
    name: "colorMode",
    initialState,
    reducers: {
        // toggleColorMode: (state: IColorMode) => {
        //     const newState = !state.darkMode;
        //     state.darkMode = newState;
        // },
        setColorMode: (state: IColorMode, action: PayloadAction<boolean>) => {
            state.darkMode = action.payload;
        },
        setColorModeOption: (
            state: IColorMode,
            action: PayloadAction<EColorModeOption>,
        ) => {
            state.option = action.payload as EColorModeOption;
        },
        setChromeOption: (
          state: IColorMode,
          action: PayloadAction<EChromeModeOption>,
        ) => {
            state.color = action.payload as EChromeModeOption;
        },
    },
});

export const {
  setColorMode,
  setColorModeOption,
  setChromeOption } = colorModeSlice.actions;

// export const selectColorModeDark = (state: RootState) =>
//     state.colorMode.darkMode;
// export const selectColorModeDark = (state: IColorMode) => state.darkMode;

export default colorModeSlice.reducer;
