import React from "react";
import LightLogo from "../../../src/assets/images/SR_Meteor_Black_Logo_Transparent_bg.svg"
import DarkLogo from "../../../src/assets/images/SR_Meteor_White_logo_Transparent_bg.svg"
import {useAppSelector} from "../../redux/hooks";

export const BackLogo = () => {
  const darkMode = useAppSelector(s => s.colorMode.darkMode);

  return (
    <img src={darkMode ? DarkLogo : LightLogo} className="App-logo" alt="logo"
         style={{
           marginTop: 20,
           width: "50%",
           position: "fixed",
           top: "50%",
           left: "50%",
           transform: "translate(-50%, -50%)",
           opacity: "0.1"

         }}
    />
  );
};
