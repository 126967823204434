import { useEffect, useState } from "react";

export function useAddToHomeScreenPrompt(): [
        BeforeInstallPromptEvent | null,
    () => void
] {
    const [prompt, setState] = useState<BeforeInstallPromptEvent | null>(null);

    const promptToInstall = () => {
        if (prompt) {
            return prompt.prompt();
        }
        return Promise.reject(
            new Error(
                '### Tried installing before browser sent "beforeinstallprompt" event'
            )
        );
    };

    useEffect(() => {
        const ready = (e: BeforeInstallPromptEvent) => {
            // console.log(`### BeforeInstallPromptEvent: ${e.platforms}`);
            e.preventDefault();
            setState(e);
        };

        // console.log("### Registering BeforeInstallPromptEvent");
        window.addEventListener("beforeinstallprompt", ready as any);

        return () => {
            window.removeEventListener("beforeinstallprompt", ready as any);
        };
    }, []);

    return [prompt, promptToInstall];
}
