import React, {FC, useEffect} from "react";
import {Box} from "@mui/material";
import {About} from "../../components/about";
import {Helmet} from "react-helmet-async";

interface AboutPageProps {
}

export const AboutPage: FC<AboutPageProps> = (props) => {
  useEffect(() => {
    document.title = "About";
  }, []);
  return (
    <Box
      p={5}
      width="100vw"
      height="95vh"
      minHeight="95vh"
      position="fixed"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-evenly"
      justifyItems="center"
      alignItems="flex-start"
      overflow="auto"
      sx={{
        backgroundColor: "transparent",
        WebkitOverflowScrolling: "touch"
      }}
    >
      <Helmet>
        <title>SR Meteor - About</title>
        <meta name="description" content="Crestron Programming, React-Typescript, Websocket, Webconsole" />
      </Helmet>
      <About />
    </Box>
  );
}

export default AboutPage;


