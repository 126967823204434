import { useAddToHomeScreenPrompt } from "../../hooks/use-add-to-home-screen-prompt";
import React, { FC, useEffect, useState } from "react";
import {Box, Button} from "@mui/material";

export const InstallPwaComponent: FC = () => {
  const [prompt, promptToInstall] = useAddToHomeScreenPrompt();
  const [isPromptVisible, setIsPromptVisible] = useState(false);

  useEffect(() => {
    if (prompt) {
      // console.log(`### Received Prompt: ${prompt.platforms}`)
      setIsPromptVisible(true);
    }
  }, [prompt]);

  return (
    <Box position="fixed" left={8} top={60} >{isPromptVisible &&
      <Button color="warning" onClick={promptToInstall}>Install</Button>}</Box>
  );
};

export default InstallPwaComponent;
