import React, {useMemo} from "react";
import {Box, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {useAppSelector} from "./redux/hooks";
import {Route, Routes} from "react-router-dom";
import Header from "./components/header";
import HomePage from "./containers/home-page";
import AboutPage from "./containers/about-page";
import ContactPage from "./containers/contact-page"
import Disclaimer from "./components/footer";
import {BackLogo} from "./components/logo-back";
import { Analytics } from '@vercel/analytics/react';
import {Helmet} from "react-helmet-async";

function App() {

  const darkMode = useAppSelector(s => s.colorMode.darkMode);


  // Create Theme (with custom breakpoints)
  const curTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
          background: {
            default: darkMode ? "#121212" : "#f5f5f5", // set your preferred colors
          },
          text: {
            primary: darkMode ? "#f5f5f5" : "#121212",  // set your preferred colors
          },
        },
        // Custom Breakpoints
        breakpoints: {
          values: {
            xs: 0,
            sm: 550,
            md: 900,
            lg: 1200,
            xl: 1536,
          },
        },
      }),
    [darkMode],
  );



  return (
    <ThemeProvider theme={curTheme}>
      <Box
        id="App"
        mb={5}
        sx={{
          display: 'flex',
          alignSelf: 'center',
          backgroundColor: curTheme.palette.mode === 'dark' ? '#0f0f0f' : '#f0f0f0',
          color: curTheme.palette.mode === 'dark' ? '#f0f0f0' : '#0f0f0f',
          height: "100vh",
          width: '100vw',         // full viewport width
          minHeight: '100vh',        // full viewport height
          minWidth: 300,
          overflow: 'auto',
        }}
      >
        <Helmet>
          <title>SR Meteor - Software Develop</title>
          <meta name="description" content="Crestron c# Programming and Crestron React-Typescript" />
        </Helmet>
        <Header />
        <BackLogo />
        <Disclaimer />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
        <Analytics />
        <CssBaseline/>
      </Box>
    </ThemeProvider>
  );
}

export default App;
