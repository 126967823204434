import React, {FC, useEffect, useState} from "react";
// import {useAppSelector} from "../../redux/hooks";
import {Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, Tooltip, Typography} from "@mui/material";
import {
  BulletTypography,
  ExpandMoreCustom,
  fullCardBackground, fullCardBorder,
  fullCardShadow
} from "../custom-utility";
import {BulletMinusIcon, ExpandMoreIcon} from "../../assets/icons";
import Image1 from "../../assets/images/Control-2.jpg";
import Image2 from "../../assets/images/Server-2.jpg";
import Image3 from "../../assets/images/Control-4.jpg";
import Image4 from "../../assets/images/Server-4.jpg";
import {useAppSelector} from "../../redux/hooks";

interface ControlSystemProps {
}

const images = [Image1, Image2, Image3, Image4];

export const ControlSystem: FC<ControlSystemProps> = () => {
  const [imgIndex, setImgIndex] = useState<number>(3);
  const [isSwitching, setIsSwitching] = useState(false);

  const [more, setMoreExpanded] = useState(false);

  const darkMode = useAppSelector(s => s.colorMode.darkMode);
  const chromeMode = useAppSelector(s => s.colorMode.color);


  useEffect(() => {
    const slider = setInterval(() => {
      setIsSwitching(true); // Set switching state to true
      //wait for as long as your transition duration then update the state
      setTimeout(() => {
        setImgIndex(prevInd => (prevInd + 1) % images.length);
        setIsSwitching(false); // Set switching state to false
      }, 1000); // corresponds to transition duration on your css
    }, 12000); // slider timer

    return () => {
      clearInterval(slider);
    }
  }, []);

  const handleMoreExpandClick = () => {
    setMoreExpanded(!more);
  };

  return (
    <Card
      elevation={3}
      sx={{
        m: 2,
        mt: 5,
        width: { xs: 320, sm: 380, md: 435, lg: 520, xl: 600 },
        minWidth: 260,
        backgroundImage: fullCardBackground(darkMode, chromeMode),
        boxShadow: fullCardShadow(darkMode, chromeMode),
        border: fullCardBorder(darkMode, chromeMode),
      }}
    >
      <CardHeader
        title="Crestron® Control System"
        subheader="VC-4 Server or 4-Series Processor" />
      <Tooltip title={`generated using ®Image Creator`} arrow placement="top-end">
        <CardMedia
          component="img"
          image={images[imgIndex]}
          // width="20%"
          sx={{
            transition: "opacity 1s ease-in-out",
            opacity: isSwitching ? 0 : 1, // The image will fade in smoothly.
            // backgroundImage: `url(${images[imgIndex]})`,
            backgroundSize: 'cover',
            width: "96%",
            ml: "2%",
            // mr: 1,
          }}
          alt={`ControlSystem-${imgIndex + 1}`}
        />
      </Tooltip>
      <CardContent>
        <Typography paragraph >
          Start using c# and take full control of your system.
          We can help you not to start from scratch, give you
          working examples or even create full program for you to
          easy maintain and upgrade ...
        </Typography>
        <CardActions disableSpacing>
          <Typography onClick={handleMoreExpandClick} variant="button" ml={1}>
            More ...
          </Typography>
          <ExpandMoreCustom
            expand={more}
            onClick={handleMoreExpandClick}
            aria-expanded={more}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMoreCustom>
        </CardActions>
        <Collapse
          timeout="auto"
          unmountOnExit
          in={more}>
          <BulletTypography text="
            This is a process and can't be done overnight, but after first results,
            you will never want to go back and system will become more scalable and
            much easier to maintain
          " />
          <BulletTypography text="
            If you have some custom stuff that can't be transformed at the time,
            no problem, you can leave SimplWindows program in another Slot/Room and
            just use intersystem connection.
          " />
          <BulletTypography text="
            Stop creating program for specifing control system model,
            let the software discover executing platform and configure system
            depending on that
          " />
          <BulletTypography text="
            Stop hard-coding device models and other system specific information,
            create json based system configuration instead. It will make your sytem reusable
          " />
          <BulletTypography text="
            We will help you developing custom drivers for various
            3rd party systems
          " />
          <BulletTypography text="
            We can provide some utility stuff, so you don't need to develop
            from scratch
          " children={
            <>
              <BulletTypography text="
                WebConsole, developed for Crestron VC-4 servers but works with Crestron 4-Series Processors also
                " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Custom Websocket Server, working with touchpanels and SimplWindows programs
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Custom Logger working in conjunction with webconsole
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Remote Logger for SimplWindows programs, used to debug SimplSharp Libraries
                in webconsole
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
              <BulletTypography text="
                Bridge program for Crestron 3-series processors: If you have Crestron VC-4 server and some old
                CP3, DIN-AP3, MC3 or even RMC3 (Crestron 3-Series Processor), with this software you can use Processor's hardware in
                Crestron VC-4 server c# program:
                Cresnet®, Digital Inputs/Outputs, Serial Ports, etc.
              " bulletIcon={<BulletMinusIcon sx={{fontSize: "0.8rem"}} />} />
            </>
          }/>
        </Collapse>
      </CardContent>
    </Card>
  );
};

